
import Vue from "vue";
export default Vue.extend({
  name: "DisplayLoopingData",
  props: {
    data: {
      type: Array,
      required: true,
      default: () => {}
    }
  }
});
